<template>
  <immovable-reg :typePage="typePage"></immovable-reg>
</template>

<script>
import ImmovableReg from "@/views/modules/collection/immovable/immovableReg.vue";

export default {
    name: "manage",
    components: {ImmovableReg},
    data(){
        return{
            typePage:'manage'
        }
    },
}
</script>

<style scoped>

</style>